import React from "react";
import premiumImg from "../../images/premium6.png";
import "./Style.css"

const Premium3 = () => {
    return (
        <div className="slide">
            <span className="devices-text life-better-text encrypted-text">More Time With Abba</span>
            <div>
                <span className="develop-text encrypted-inf-text">Develop your <span>personal</span> relationship with your</span>
                <span className="encrypted-inf-text data-text"> heavenly father is always better!</span>
            </div>
            <div className="premiumImg">
                <img src={premiumImg} alt="Premium-6" />
            </div>                        
            <a href="http://" className="footer-text foter-text">See the full list of Premium features</a>
        </div>
    );
};

export default Premium3;