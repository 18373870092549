import { useState,useEffect } from 'react';
import { useHistory }  from 'react-router-dom';
import * as Realm from "realm-web";
const REALM_APP_ID = "learningproject-woqnx";

const Logout = () => {
    const [loading, setLoading] = useState(true)
    const history               = useHistory();

    const login = () =>{
        history.push("/login");
    }

    const GoHome = () =>{
        return (
            <>
                <div id="root">
                    <div className="container login-container">
                        <h1>You are out</h1>
                        <button onClick={login} style={{width: "70px", height: "30px"}}>
                            Login
                        </button>
                    </div>
                </div>
            </>
        )
    }

    useEffect(() => {
        const app = new Realm.App({ id: REALM_APP_ID });
        const logout = async () =>{
            try {
                const user = await app.currentUser.logOut();
                console.log(user);
                localStorage.clear();
                setLoading(false)
            } catch (error) {
                console.log('Fetch Error :-S', error);
                localStorage.clear();
                setLoading(false)
            }
        }
        logout();
    }, []);

    if(loading){
        return <h3>Loading...</h3>
    }
    return <GoHome/>
};

export default Logout;
