import React, { useState, memo } from 'react';
import Login          from "./pages/Login";
import Logout          from "./pages/Logout";
import Home           from "./pages/Home";
import About          from "./pages/About";
import Privacy        from "./pages/Privacy";
import Settings       from "./pages/Settings";
import PremiumPricing from "./pages/PremiumPricing";
import ErrorPage          from "./pages/Error";
import {ErrorBoundary} from 'react-error-boundary'
import { AuthProvider } from "./contexts/AuthContext"
import { Switch, Route, useLocation} from "react-router-dom";
import PrivateRoute from "./components/Routes/PrivateRoute"
import Modal from "./components/Modal/Modal";
import SortContext from "./contexts/SortContext";
import ResetPassword from './components/ResetPassword/ResetPassword';

function ErrorFallback({error}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{color: 'red'}}>{error.message}</pre>
    </div>
  )
}

function App() {
  const location = useLocation();
  const background = location.state && location.state.background;
  const [postSorting, setPostSorting] = useState('date');
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [searchRange, setSearchRange] = useState(false);
  const [filterDate, setFilterDate]= useState(false);
  const [changePass, setChangePass] = useState(false);


  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuthProvider>
            <SortContext.Provider value={{postSorting, setPostSorting, dateStart, setDateStart, dateEnd, setDateEnd, searchRange, setSearchRange, filterDate, setFilterDate, changePass, setChangePass}}>
              <Switch location={background || location}>
                <Route exact path="/login"          component={Login} />
                <Route exact path="/logout"         component={Logout} />
                <PrivateRoute exact path="/"        component={Home} />
                <Route exact path="/about"          component={About} />
                <Route exact path="/privacy"        component={Privacy} />
                <Route exact path="/settings"       component={Settings} />
                <Route exact path="/premiumpricing" component={PremiumPricing} />
                <Route path="/resetpassword"  component={ResetPassword} />
                <Route exact path="*"               component={ErrorPage} />                
              </Switch>
              {background && <Route path="/posts/:postid" children={<Modal />} />}
            </SortContext.Provider>
        </AuthProvider>
    </ErrorBoundary>
  );
}

export default memo(App);