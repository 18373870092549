import React from "react";
import userImg from "../images/user.png";
import settingsImg from "../images/settings-icon.png";
import premiumImg from "../images/WJ-Symbol.png";
import { Link } from "react-router-dom";
import Button from "../components/Button/Button";

const Settings = () => {
    let userId = "e78rg67g4ui5690ht";
    let userAccount = "Basic";
    return (
        <div className="container">
            <div className="settings-tittle">
                <img src={settingsImg} className="user-logo" alt="User-Logo" />
                <span className="settings-text">Settings</span>
            </div>
            <div className="user-info">
                <div className="user-id">
                    <img src={userImg} className="user-logo" alt="User-Logo" />
                    <div className="user-id-info">
                        <span>Beloved User ID:</span>
                        <span>{userId}</span>
                    </div>
                </div>
                <div className="user-account-status">
                    <span className="user-status">
                        {" "}
                        Account Status: <span>{userAccount}</span>
                    </span>
                </div>

                <button className="user-premium-button">
                    <div className="user-premium">
                        <img src={premiumImg} alt="With-Jesus-Symbol-White" />
                        <div className="user-premium-text">
                            <span>Get Life With Abba Premium</span>
                            <span>
                                Encrypted Sync, unlimted, photos, videos, &
                                audio recordings, and more.
                            </span>
                        </div>
                    </div>
                </button>
            </div>
            <div className="users-links">
                <ul>
                    <li>
                        <Link to="/About">About</Link>
                    </li>
                    <li>
                        <Link to="/">Rate</Link>
                    </li>
                    <li>
                        <Link to="/">Share</Link>
                    </li>
                    <li>
                        <Link to="/">Security</Link>
                    </li>
                    <li>
                        <Link to="/">Contact</Link>
                    </li>
                    <li>
                        <Link to="/Privacy">Privacy</Link>
                    </li>
                </ul>
            </div>
            <div className="user-buttons">
                <Button label="GO BACK" classN="button-primary button" />
                <Button label="SIGN OUT" classN="button-secundary button" />
            </div>
        </div>
    );
};

export default Settings;
