import Switch, { Case } from "react-switch-case";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay }   from "@fortawesome/free-solid-svg-icons";
import defaultImage from "../../images/ImagePost.png";
import quoteImage   from "../../images/quote_mark.png";
import Audio        from "./Player/Audio/Audio";
import Video        from "./Player/Video/Video";
import { Link, useLocation } from "react-router-dom";

const PostType = (props) => {
    
    const location  = useLocation();
    const postImage = () => {
        try {
            return `data:image/gif;base64,${props.staticContent.ImageData.ImageString}`;
        } catch (error) {
            return defaultImage;
        }
    };

    return (
        <div className="posterPost" >
            <Switch condition={String(props.type)}>
                <Case value="1">
                    {props.display !== "all" ? (
                        <div className="image-container">
                            <img src={postImage()} className="postImage" alt="Postimage" />
                        </div>
                    ): (
                        <Link to={{ pathname: `/posts/${props._id}`, state: { background: location },  posts: props.posts   }} >
                            <div className="image-container">
                                <img src={postImage()} className="postImage" alt="Postimage" />
                            </div>
                        </Link>
                    )}                    
                </Case>
                <Case value="2">
                    {props.display !== "all" ? (
                        <div className="emoji-container">
                            <img src={postImage()} className="emojiPost" alt="Postimage" />
                            <div className="title">
                                <p>{props.dynamicContent.UserTextLabel}</p>
                            </div>
                        </div>
                    ): (
                        <Link to={{ pathname: `/posts/${props._id}`, state: { background: location },  posts: props.posts   }} >
                            <div className="emoji-container">
                                <img src={postImage()} className="emojiPost" alt="Postimage" />
                                <div className="title">
                                    <p>{props.dynamicContent.UserTextLabel}</p>
                                </div>
                            </div>
                        </Link>
                    )}                     
                </Case>
                <Case value="3">
                    {props.display !== "all" ? (
                        <div className="verse-container">
                            <img src={quoteImage} alt="quoteImage" />
                            <div className="title verse">
                                <p className="verse">{props.staticContent.Caption}</p>
                                <p className="verseRef">
                                    {props.staticContent.BibleVerseRef}
                                </p>
                            </div>
                        </div>
                    ): (
                        <Link to={{ pathname: `/posts/${props._id}`, state: { background: location },  posts: props.posts   }} >
                            <div className="verse-container">
                                <img src={quoteImage} alt="quoteImage" />
                                <div className="title verse">
                                    <p className="verse">{props.staticContent.Caption}</p>
                                    <p className="verseRef">
                                        {props.staticContent.BibleVerseRef}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    )} 
                </Case>
                <Case value="4">
                    {props.display !== "all" ? (
                        <div className={`text-container ${ props.display !== "all" && "show-more" }`} >
                            <textarea className="text-container" value={props.staticContent.Caption} readOnly > </textarea>
                        </div>
                    ): (
                        <Link to={{ pathname: `/posts/${props._id}`, state: { background: location },  posts: props.posts   }} >
                            <div className={`text-container ${ props.display !== "all" && "show-more" }`} >
                                {/* <textarea className="textContainer" value={props.staticContent.Caption} readOnly ></textarea> */}
                                <div className="textContainer">{props.staticContent.Caption}</div>
                            </div>
                        </Link>
                    )}
                </Case>
                <Case value="5">
                    {props.display !== "all" ? (
                        <div className="audio-container">
                            <img className="audioPostImage" src={postImage()} alt="" />
                            <Audio {...props} />
                            <div className="title">
                                <p>{props.dynamicContent.UserTextLabel}</p>
                            </div>
                        </div>
                    ) : (
                        <Link to={{ pathname: `/posts/${props._id}`, state: { background: location },  posts: props.posts   }} >
                            <div className="audio-container">
                                <img className="audioPostImage" src={postImage()} alt="" />
                                <div className="title">
                                    <p>{props.dynamicContent.UserTextLabel}</p>
                                </div>
                            </div>
                        </Link>
                    )}
                </Case>
                <Case value="6">
                    {props.display !== "all" ? (
                        <div className="video-container">
                            <Video {...props} />
                        </div>
                    ) : (
                        <Link to={{ pathname: `/posts/${props._id}`, state: { background: location },  posts: props.posts   }} >
                            <div className="video-container">
                                <div className="play-icon-container">
                                    <div className="play-icon">
                                        <FontAwesomeIcon icon={faPlay} className="playButton" size="2x" />
                                    </div>
                                </div>
                                <img src={postImage()} className="postImage" alt="Postimage" />
                            </div>
                        </Link>
                    )}
                
                </Case>
            </Switch>
        </div>
    );
};
export default PostType;
