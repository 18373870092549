import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

export default function Play(props) {
    const { handleClick } = props;

    return (
        <button className="player__button iconPlayer" onClick={() => handleClick()}>
            <FontAwesomeIcon icon={faPlay} />
        </button>
    );
}
