import React, {useContext, useState, useEffect} from "react";
import loginImage from "../images/ImageLogin.png";
import logoImage from "../images/logo.png";
import AppStoreImage from "../images/AppStore.png";
import GoogleStoreImage from "../images/GoogleStore.png";
import Signup from "../components/FormSignup/FormSignup";
import Carousel from "infinite-react-carousel";
import Premium1 from "../components/Premium/Premium1";
import Premium2 from "../components/Premium/Premium2";
import Premium3 from "../components/Premium/Premium3";
import Premium4 from "../components/Premium/Premium4";
import Premium5 from "../components/Premium/Premium5";
import Premium6 from "../components/Premium/Premium6";
import SortContext from "../contexts/SortContext";
import * as Realm from "realm-web";
import env from "react-dotenv";
import Swal from "sweetalert2";

const REALM_APP_ID = env.REALM_APP_ID;
const app          = new Realm.App({ id: REALM_APP_ID });

const Login = () => {
    const settings = {
        arrows: false,
        dots: false,
        duration: 300,
        pauseOnHover: false,
        swipe: false,
        autoplay: true,
        autoplaySpeed: 6000,
    };
    const {changePass}= useContext(SortContext);
    const {setChangePass} = useContext(SortContext);
    const [forgotPass, setForgotPass]= useState(true);
    const [disableButton, setDisableButton]= useState(true);
    const [noEmail, setNoEmail]= useState(false);

    document.getElementById('main').style.backgroundColor= "rgb(250,250,250)"; 

    const toggleClass = () => {
        setForgotPass(!forgotPass);
        setDisableButton(false);        
    };

    useEffect(() => {
        if(!forgotPass){
            setChangePass(false)
        }else{
            setChangePass(false)
        }
    },[forgotPass, setChangePass]);

    async function onSubmit() {
        const email = document.getElementById("emailReset").value;
        const validation=valEmail(email);
        const newPassword = "newPassw0rd";

        if (validation) {
            try {            
                //await app.emailPasswordAuth.sendResetPasswordEmail({ email });
                await app.emailPasswordAuth.callResetPasswordFunction(email, newPassword);

                Swal.fire({
                    title: 'Your reset link was send it to your email account.',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        toggleClass();
                    }
                });
            } catch (error) {
                Swal.fire({
                    title: "The account "+email+" doesn't exist.",
                    confirmButtonText: 'OK'
                });
            }   
        }else{
            setNoEmail(true);
        }      
    }

    const keyboardEvents = (event) =>{
        let email = document.getElementById('emailReset').value;
        event.persist();
        
        if (email.length >= 3) {
            setDisableButton(false);
            document.getElementById('sendEmail').style.opacity= "1";
            document.getElementById('sendEmail').style.cursor= "pointer";
        }else{
            setDisableButton(true);
            document.getElementById('sendEmail').style.opacity= "0.4";
            document.getElementById('sendEmail').style.cursor= "inherit";
        } 
    }

    function valEmail( email ){
        // eslint-disable-next-line no-useless-escape
        var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email) ? true : false;
    }

    return (
        <div id="root" className="rootLogin">
            <div className="container login-container">
                <div className="login-demo" style={{display:"none"}}>
                    <div className="loginDemoContainer">
                        <img
                            src={loginImage}
                            className="loginImg"
                            alt="Logo-Abba"
                        />
                        <div className="login-carousel">
                            <Carousel {...settings}>
                                <div>
                                    <Premium1 />
                                </div>
                                <div>
                                    <Premium2 />
                                </div>
                                <div>
                                    <Premium3 />
                                </div>
                                <div>
                                    <Premium4 />
                                </div>
                                <div>
                                    <Premium5 />
                                </div>
                                <div>
                                    <Premium6 />
                                </div>
                            </Carousel>
                        </div>
                    </div>                    
                </div>
                { changePass ? (
                    <div id="login-form">
                        <div className="login-data">
                            <img src={logoImage} className="logoLoginImg" alt="Logo-Abba" />
                            <div className="emailResetContainer">
                                <p className="resetText">Enter your email and we'll send you a link to reset your account password.</p>
                                <input type="email" name="email" id="emailReset" className="email input login-input" placeholder="E-Mail" onKeyUp={keyboardEvents} required />
                                {noEmail ? (<p style={{color:"red", fontSize:"14px", textAlign:"left", width:"274px", margin:"0 auto"}}>Enter a correct email format</p>) : (<span></span>)}
                                <button id="sendEmail" className="button-primary button login-button sendEmail" onClick={onSubmit} disabled={disableButton} > Send Reset Password Link </button>
                            </div>
                            <div className="backToLoginContainer">
                                <button className="backToLogin" onClick={toggleClass} > Back To Login </button>
                            </div>
                            <div className="container-title">
                                <p className="tittle">
                                    Welcome, <span className="text-beloved">Beloved.</span>
                                </p>
                                <span>Came in and share your ...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div id="login-form">
                        <div className="login-data">
                            <img src={logoImage} className="logoLoginImg" alt="Logo-Abba" />
                            <Signup />
                            <div className="container-title" style={{display:"none"}}>
                                <p className="tittle">
                                    Welcome, <span className="text-beloved">Beloved.</span>
                                </p>
                                <span>Came in and share your ...</span>
                            </div>
                        </div>
                        <div className="account">
                            <span className="new-account">
                                <p>Don't have an account?</p>
                                <a href="/" className="create-account">
                                    Signup in the app
                                </a>
                            </span>
                        {/* </div>
                        <div className="getApp-container"> */}
                            {/* <p>Get the app.</p> */}
                            <div className="app-stores">
                                <a className="appleIcon" href="https://www.apple.com/app-store/">
                                    <img src={AppStoreImage} className="" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                )}                
            </div>
        </div>
    );
};

export default Login;
