import React, { useState, useEffect, useRef, useContext, memo } from "react";
// import PostType                        from "./PostType";
// import PostInformation                 from "./PostInformation";
// import PostDate                        from "./PostDate";
// import PostDescription                 from "./PostDescription";
// import PostComments                    from "./PostComments";
import LoadingPosts                    from "../LoadingPosts/LoadingPosts";
import Masonry, { ResponsiveMasonry }  from "react-responsive-masonry";
import  SortContext                        from "../../contexts/SortContext"
import "./Posts.css";
import PreLoader from "../PreLoader/PreLoader";
import SinglePost from "./SinglePost";
import HomePosts from "./HomePosts";
//import LoadingDots from "../LoadingDots/LoadingDots";

// const Post = (props) => {
//     return (
//         <article className="post" >
//             <PostType {...props} />
//             <div className="post-details">
//                 <div className="postDetailsRow">
//                     <PostInformation {...props}/>
//                     <PostDate {...props}/>
//                 </div>
//                 <PostDescription {...props} />
//             </div>
//         </article>
//     );
// };
// const SinglePost = (props) => {
//     const handleModalPost = (e) => e.stopPropagation();
//     console.log("SinglePost", props.changePostModal);

//     return (
//         <article id="singlePost" className={"singlePost "+ props.changePostModal} onClick={handleModalPost}>
//             <PostType {...props} />
//             <div className="infoPost">
//                 <PostDescription {...props} />
//                 <PostComments {...props} />
//             </div>
//         </article>
//     );
// };
const timeTicks = () => {
    const dateNow = new Date();
    var offset = -300;
    var estDate = new Date(dateNow.getTime() + offset*60*1000);
    const ticks   = ((estDate*10000)+621355968000000000);
    
    return ticks;
}
const GetPosts = (props) => {
    console.log("GetPosts");
    const loadPostNumber = (props.display === 'all') ? 15 : 1;
    const [posts, setPosts ]          = useState([]);
    const [postsScroll, setPostsScroll ]= useState([]);
    // eslint-disable-next-line no-unused-vars
    const [loadMore, setLoadMore]     = useState(0);
    const [loading, setLoading]       = useState(false);
    const [end, setEnd]               = useState(false);
    const lastPost                    = useRef(0);
    const processing                  = useRef(false);
    const postIds                     = useRef([]);
    const {postSorting}= useContext(SortContext);
    const {dateStart}= useContext(SortContext);
    const {dateEnd}= useContext(SortContext);
    const {searchRange, setSearchRange}= useContext(SortContext);
    const [postLoad, setPostLoad]= useState(true);
    const {filterDate}= useContext(SortContext);

    const username= Buffer.from(localStorage.getItem("text1"), "base64").toString();
    const pass= Buffer.from(localStorage.getItem("text2"), "base64").toString();
    const encrypKey= Buffer.from(localStorage.getItem("text3"), "base64").toString();
    const [loaderLogin, setLoaderLogin]       = useState(true);

    document.getElementById('main').style.backgroundColor= "#eaeaea";

    useEffect(()=>{
        if(loaderLogin){
            document.getElementById('likesButton').style.pointerEvents="none";
            document.getElementById('iconCalendar').style.pointerEvents="none";
            return true
        }
        document.getElementById('iconCalendar').style.pointerEvents="inherit";
        document.getElementById('iconCalendar').style.cursor="pointer";
        document.getElementById('likesButton').style.pointerEvents="inherit";
        document.getElementById('likesButton').style.cursor="pointer";
        return true
    }, [loaderLogin, loading]);

    useEffect(() => {
        setPosts([]);
        setEnd(false);
        setLoading(false);
        setPostLoad(true);
        postIds.current = [];
        if(postSorting === 'date' && filterDate=== false){
            lastPost.current = timeTicks();
            return true
        }
        if(filterDate=== true){
            lastPost.current = dateStart;
            return true
        }
        lastPost.current = 10000;
        return true
    },[postSorting, dateStart, filterDate, searchRange])

    useEffect(() => {
        let mounted = true;        
        if (mounted) {            
            const postResponse   = async () =>{
                if(!processing.current){
                    processing.current = true;
                    if(!end){
                        if (!postLoad) {
                            setLoading(true);
                        }
                        const postLimit     = () =>{
                            const widthScreen = window.screen.width;
                            let postsByRow    = 0;
                            if(widthScreen > 1300) {
                                postsByRow = 5;
                            }else if(widthScreen > 1020){
                                postsByRow = 4;
                            }else if(widthScreen > 760){
                                postsByRow = 3;
                            }else if(widthScreen > 510){
                                postsByRow = 2;
                            }else{
                                postsByRow = 2;
                            }
                            return Math.round((window.screen.height / 400)*postsByRow)+(postsByRow*2);
                        }
                        try {                            
                            if(props.display === 'all'){
                                const sortingBy = () =>{
                                    if(postSorting === 'date'){
                                        return { 'timeLabel': -1 };
                                    }else{
                                        return { 'dynamicContent.Likes': -1};
                                    }
                                }
                                const searchingBy = () =>{
                                    if(postSorting === 'date' && filterDate=== false){
                                        return { 'timeLabel':{'$lt': lastPost.current}, _id: { '$nin': postIds.current }};
                                    }else if(filterDate){
                                        return { 'timeLabel':{'$gte': dateStart, '$lte': dateEnd}, _id: { '$nin': postIds.current }};
                                    }else{
                                        return {'dynamicContent.Likes':{'$lte': lastPost.current}, _id: { '$nin': postIds.current }};
                                    }
                                }

                                const url= "https://work.nexlevsolutions.com/posts";

                                let myHeaders = new Headers();
                                myHeaders.append("Content-Type", "application/json");

                                //console.log("dateStart ",dateStart,"     dateEnd ",dateEnd,"     searchRange ",searchRange);

                                //console.log("filterDate", filterDate);
                                let raw = JSON.stringify({"user":username,"password":pass,"encrypKey":encrypKey,"searchingBy":searchingBy(),"sortingBy":sortingBy(),"postLimit":postLimit(),"dateStart":dateStart,"dateEnd":dateEnd,"searchRange":filterDate});
                                
                                let requestOptions = {
                                    method: 'POST',
                                    headers: myHeaders,
                                    body: raw,
                                    redirect: 'follow'
                                };

                                const requestFetch= await fetch(url, requestOptions)
                                const resultFetch= await requestFetch.json();

                                //console.log("resultFetch.message",resultFetch);

                                const postResponse= resultFetch.message;

                                setPostsScroll(resultFetch.message)
                                
                                postIds.current =  postIds.current.concat(postResponse.map(({ _id }) => _id))

                                if(postResponse.length < 1){
                                    setEnd(true);
                                }
                                setPosts(p => [...p,...postResponse])
                                setLoading(false)
                                setLoaderLogin(false)
                                setPostLoad(false);
                                processing.current = false

                                return true
                            }                           
                            
                            const url= "https://work.nexlevsolutions.com/post/:"+props.display;

                            let myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");

                            let raw = JSON.stringify({"user":username,"password":pass,"encrypKey":encrypKey,"id":props.display});

                            let requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: raw,
                                redirect: 'follow'
                            };
                                    
                            const requestFetch= await fetch(url, requestOptions)
                            const resultFetch= await requestFetch.json();

                            setPosts(resultFetch.message)
                            setLoading(false)
                            setLoaderLogin(false)
                            processing.current = false
                            return true
                        } catch (error) {                            
                            setLoading(false)
                            setLoaderLogin(false)                            
                            processing.current = false
                            return false
                        }   
                    }
                }
            }
            setLoaderLogin(true);
            postResponse();
            setSearchRange(false);            
        }        
        return () => {            
            mounted = false;
        };        

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMore, postSorting, props.display, end, username, pass, encrypKey, searchRange]);

    useEffect(() => {
        const handleScroll = ()=> {
            if(window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight-800){
                if(props.display === 'all' && (!loading) && postsScroll.length >=1){
                    setLoadMore(prevCount => prevCount + 1);  
                    setPostLoad(false);
                    if (searchRange) {
                        setPostLoad(true);
                    }
                }
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    if (props.display === "all") {
        console.log("all");
            return (
                <>
                    <ResponsiveMasonry columnsCountBreakPoints={{ 250: 2, 610: 3, 810: 4, 1010: 5, 1210: 6, 1411: 7 }} className="responsiveMasonry" >
                        <Masonry className="masonry">
                            {posts.length > 0 && posts.map((el) => (
                                <HomePosts key={el._id.toString()} {...props} {...el} posts={posts}/>
                            ))}
                            {loaderLogin && <PreLoader postLoad={postLoad} />}
                            {loading && ( [...Array(loadPostNumber)].map((e, i) => <LoadingPosts key={i}/>) )}
                            
                        </Masonry>
                    </ResponsiveMasonry>
                    {/* {<LoadingDots />} */}
                </>
            );
    } else {
        return (
            <>  
                {posts.length > 0 && posts.map((el) => (
                    <SinglePost changePostModal={props.changePostModal} key={el._id.toString()} {...props} {...el} />
                ))}
                <LoadingPosts key={1} index={1}/>
            </>
        );
    }
};

export default memo(GetPosts);
