import "./PreLoader.css";

function PreLoader(props) {
    if (props.postLoad) {
        return (
            <div id="loader-wrapper">
                <div id="loader">
                    <ul className="cssload-flex-container">
                        <li><span className="cssload-loading"></span></li>
                    </ul>
                </div>
            </div>
        );        
    }else{
        return(
            <></>
        );
    }
}

export default PreLoader;