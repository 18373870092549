import React from "react";
import "./NavMenu.css";
import logoImage from "../../images/logo.png";
import NavOptions from "./NavOptions";
import NavAccess from "./NavAccess";
import { useAuth } from "../../contexts/AuthContext";

const NavMenu = () => {

    const { user } = useAuth();

    return (
        <div id="navMenu-container">
            <div id="navMenu">
                <div className="tittle">
                    <a href="/">
                        {/* <div className="logoMenuPulse"></div> */}
                        <div className="containerNavMenu">
                            <div>
                                <img src={logoImage} className="logoMenu" alt="Logo-Abba" />
                            </div>                        
                            <div className="circle" style={{animationDelay: "-3s"}}></div>
                            <div className="circle" style={{animationDelay: "-2s"}}></div>
                            <div className="circle" style={{animationDelay: "-1s"}}></div>
                            <div className="circle" style={{animationDelay: "0s"}}></div>
                            <span className="iconTittle" ></span>
                        </div>                        
                    </a>
                </div>
                {user ? <NavOptions /> : <NavAccess />}
            </div>
        </div>
    );
};
export default NavMenu;
