import React from "react";
import Play from "../Play";
import Pause from "../Pause";
import AudioBar from "./AudioBar";
import useAudioPlayer from "./useAudioPlayer";
import "../style.css";

function Audio(props) {  
    const { 
        curTime, 
        duration, 
        setClickedTime, 
        playing, 
        setPlaying} = useAudioPlayer();
    
    const postAudio = () => {
        try {
            return `data:audio/mp4;base64,${props.staticContent.FileName}`;
        } catch (error) {
            return '';
        }
    };

    return (
        <div className="player">
            <audio id="audio">
                <source src={postAudio()} preload="metadata" />
                Your browser does not support the <code>audio</code> element.
            </audio>
            <div className="audioControls">
            {playing ? (
                    <Pause handleClick={() => setPlaying(false)} />
                ) : (
                    <Play handleClick={() => setPlaying(true)} />
                )}           
                <AudioBar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} />
            </div>
        </div>
    );
}

export default Audio;
