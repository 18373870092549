import React, { useState, useContext } from "react";
import "./DropCalendarSearch.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faTimesCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import  SortContext from "../../../contexts/SortContext";


const DropCalendarSearch = (props) => {
    const [showItems, setShowItems] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const {setDateStart, setDateEnd, setSearchRange, setFilterDate} = useContext(SortContext);

    const dropDown = () => {
        setShowItems(!showItems);
        cleanDatePicker();
    };

    const handleCheckInDate = (date) => {
        setStartDate(date);
    };
    
    const handleCheckOutDate = (date) => {
        setEndDate(date);
    };

    const cleanDatePicker = () => {
        setStartDate(null);
        setEndDate(null);
    };

    const dateSearch = () => {
        let startDateSearch= ((startDate*10000)+621355968000000000);
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        let endDateSearch= ((endDate*10000)+621355968000000000);
        
        setSearchRange(true);
        setDateStart(startDateSearch);
        setDateEnd(endDateSearch);
        setFilterDate(true);

        setShowItems(!showItems);
    };

    return (
        <div className="dropCalendarSearch">
            <div id="iconCalendar" className="logoCalendarSearch" onClick={dropDown}>
                <FontAwesomeIcon icon={faCalendarAlt} className={showItems ? "iconCalendar sorted" : "iconCalendar"}/>
            </div>
            <div className="arrowCalendarSearch" style={{ display: showItems ? "block" : "none" }} > </div>
            <div style={{ display: showItems ? "block" : "none" }} className="hidenCalendarSearch" >
                <div className="calendarSearchContainer" style={{ padding: "10px 10px 10px 20px" }}>
                    <div className="calendarSearchText">
                        <span className="calSearchText">Start</span>
                        <span className="calSearchText">End</span>                        
                    </div>
                    <div className="calendarSearchPicker">
                        <DatePicker
                            selected={startDate}
                            onChange={handleCheckInDate}
                            className="calendarPicker"
                            placeholderText="mm/dd/yyyy"
                            id="calendarPickerStart"
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={handleCheckOutDate}
                            minDate={startDate}
                            placeholderText="mm/dd/yyyy"
                            id="calendarPickerEnd"
                        />
                        <FontAwesomeIcon icon={faTimesCircle} className="clearCalSearch" style={{ display: startDate || endDate ? "block" : "none" }} onClick= {cleanDatePicker}/>
                        <FontAwesomeIcon icon={faSearch} className="searchButton" style={{ display: startDate && endDate ? "block" : "none" }} onClick={dateSearch}/>
                    </div>                    
                </div>
            </div>
        </div>
    );
};

export default DropCalendarSearch;