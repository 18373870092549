import React, { useRef, useState, useEffect, memo } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import GetPosts from "../Posts/Posts";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";

const Modal = () => {
    const [effect, setEffect] = useState(false);
    const location = useLocation();
    const history = useHistory();
    let { postid } = useParams();
    const nextPost = useRef("0");
    const prevPost = useRef("0");
    // eslint-disable-next-line no-unused-vars
    const [changePostModal, setChangePostModal] = useState();

    const closeModal = (e) => {
        document.getElementById("body").classList.add('scrollOff');

        setEffect(!effect)
        
        setTimeout(function(){
            history.replace("/");
        },500);      
    };

    const prevModalPost = (e) =>{
        setChangePostModal("prevPostModal")
        e.stopPropagation();
    }
    const nextModalPost = (e) =>{
        setChangePostModal("nextPostModal")
        e.stopPropagation(); 
    }

    nextPost.current = location.posts.filter(function (value, index, posts) {
        let result = false;
        if (index) {
            if (posts[index - 1]._id === postid) {
                result = true;
            }
        }
        return result;
    });

    prevPost.current = location.posts.filter(function (value, index, posts) {
        let result = false;
        if (posts.length - 1 !== index) {
            if (posts[index + 1]._id === postid) {
                result = true;
            }
        }
        return result;
    });

    useEffect(() => {
        setChangePostModal('none')
        document.getElementById("body").classList.toggle('scrollOff');
    },[effect]);

    return(            
        <div id="containerModal" className={effect ? 'containerModal out': "containerModal"} onClick={closeModal} >
            <span className="closeModal" onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} className="closeIcon" />
            </span>
            {prevPost.current.length > 0 ? (
                <div className="prevPost" onClick={prevModalPost}>
                    <Link to={{ pathname: `/posts/${prevPost.current[0]._id}`, state: { background: location.state.background }, posts: location.posts}} >
                        <FontAwesomeIcon icon={faCaretLeft} className="buttonPrev" />
                    </Link>
                </div>
            ):(
                <div className="prevPost"></div>
            )}
            <GetPosts display={postid} changePostModal={changePostModal} />
            {nextPost.current.length > 0 ? (
            <div className="nextPost" onClick={nextModalPost}>
                <Link to={{ pathname: `/posts/${nextPost.current[0]._id}`, state: { background: location.state.background }, posts: location.posts}} >
                    <FontAwesomeIcon icon={faCaretRight} className="buttonNext" />
                </Link>
            </div>
            ):(
                <div className="nextPost"></div>
            )} 
        </div>
    );
};

export default memo(Modal);
