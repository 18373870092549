import React from "react";
import premiumImg from "../../images/premium2.png";
import "./Style.css"

const Premium2 = () => {
    return (
        <div className="slide">
            <div>
                <span className="encrypted-text life-better-text">Encrypted Data</span>
                <span className="encrypted-inf-text">Your data is encrypted in transit and at rest.</span>
            </div>
            <div className="premiumImg premium-2">
                <img src={premiumImg} alt="Premium-2" />
            </div>            
            <a href="http://" className="footer-text">See the full list of Premium features</a>
        </div>
    );
};

export default Premium2;
