import React from "react";
import myLifeWithAbbaImg from "../images/logo.png";
import Button from "../components/Button/Button";

const Privacy = () => {
    return (
        <div className="container">
            <img
                src={myLifeWithAbbaImg}
                className="privacylogo"
                alt="Logo-Abba"
            />
            <div className="privacy-text">
                <p className="lastUpdate">
                    <span>Privacy Policy</span>Last updated [month day, year]
                </p>
                <p>
                    [BUSINESS ENTITY NAME] (“we” or “us” or “our”) respects the
                    privacy of our users (“user” or “you”). This Privacy Policy
                    explains how we collect, use, disclose, and safeguard your
                    information when you visit our mobile application (the
                    “Application”). Please read this Privacy Policy carefully.
                    IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY,
                    PLEASE DO NOT ACCESS THE APPLICATION.
                </p>
                <p>
                    We reserve the right to make changes to this Privacy Policy
                    at any time and for any reason. We will alert you about any
                    changes by updating the “Last updated” date of this Privacy
                    Policy. You are encouraged to periodically review this
                    Privacy Policy to stay informed of updates. You will be
                    deemed to have been made aware of, will be subject to, and
                    will be deemed to have accepted the changes in any revised
                    Privacy Policy by your continued use of the Application
                    after the date such revised Privacy Policy is posted.
                </p>
                <p>
                    This Privacy Policy does not apply to the third-party
                    online/mobile store from which you install the Application
                    or make payments, including any in-game virtual items, which
                    may also collect and use data about you. We are not
                    responsible for any of the data collected by any such third
                    party.
                </p>
                <Button label="GO BACK" classN="button-primary button" />
            </div>
        </div>
    );
};

export default Privacy;
