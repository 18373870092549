import React, {memo} from "react";
import PostType                        from "./PostType";
import PostDescription                 from "./PostDescription";
import PostInformation from "./PostInformation";
import PostDate from "./PostDate";

const HomePosts = (props) => {
    return (
        <article className="post" >
            <PostType {...props} />
            <div className="post-details">
                <div className="postDetailsRow">
                    <PostInformation {...props}/>
                    <PostDate {...props}/>
                </div>
                <PostDescription {...props} />
            </div>
        </article>
    );
};

export default memo(HomePosts);