import React, { useState,useEffect, useContext} from 'react';
import { useForm }         from 'react-hook-form';
import { useHistory}      from 'react-router-dom';
import Button              from '../Button/Button';
import { useAuth }         from '../../contexts/AuthContext';
import * as Realm from "realm-web";
import "./Style.css"
import env from "react-dotenv";
import Loader              from "../Loader/Loader";
import  SortContext from "../../contexts/SortContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const REALM_APP_ID = env.REALM_APP_ID;

const Signup = () => {
    const [loading, setLoading]      = useState()
    const [forgotPass, setForgotPass]= useState(false);
    const { register, handleSubmit } = useForm();
    const history                    = useHistory();
    const {login}                    = useAuth();
    const {setChangePass} = useContext(SortContext);
    const [showPass, setShowPass]= useState(false);
    const [showEncrypKey, setShowEncrypKey]= useState(false);

    useEffect(() => {
        const app = new Realm.App({ id: REALM_APP_ID });
        const logout = async () =>{
            try {
                // eslint-disable-next-line no-unused-vars
                const user = await app.currentUser.logOut();
                localStorage.clear();
                setLoading(false)
            } catch (error) {
                //console.log('Fetch Error :-S', error);
                localStorage.clear();
                setLoading(false)
            }
        }
        logout();
        document.getElementById('button').style.opacity= "0.4";
        document.getElementById('button').style.disabled= false;
        document.getElementById('button').style.cursor= "inherit";
    }, []);

    async function onSubmit(data) {

        if (data) {
            document.getElementById('button').style.display= "none";
            setLoading(true);
            const valUser = await login(data.email, data.password, data.encrypKey);
            if (valUser) {
                history.push("/");
            }
        } else {
            setLoading(false);
            console.log("EEEROR");
        }
    }
    const keyboardEvents = (event) =>{
        var email = document.getElementById('email').value;
        var password = document.getElementById('password').value;
        var encryptionKey = document.getElementById('encrypKey').value;
        event.persist();

        try {
            if (email.length >= 1 && password.length>= 6 && encryptionKey.length>= 8) {      
                document.getElementById('button').style.opacity= "1";
                document.getElementById('button').style.disabled= true;
                document.getElementById('button').style.cursor= "pointer";
            }else{
                document.getElementById('button').style.opacity= "0.4";
                document.getElementById('button').style.disabled= false;
                document.getElementById('button').style.cursor= "inherit";
            }
            
        } catch (error) {
            
        }        
    }

    const toggleClass = () => {
        setForgotPass(true);
    };

    useEffect(() => {
        if(forgotPass){
            setChangePass(true)
        }
    },[forgotPass, setChangePass]);

    const switchShownPass = () => {
        setShowPass(!showPass);
    };
    const switchShownEncrypKey = () => {
        setShowEncrypKey(!showEncrypKey);
    };

    return (
        <>
            <form className='formSignup' onSubmit={handleSubmit(onSubmit)}>
                <input type="email"    name="email" id="email"      className="email input login-input"          ref={register({ required: true })} placeholder="E-Mail"  onKeyUp={keyboardEvents}/>
                <input type={showPass ? "text":"password"} name="password" id="password"   className="password input login-input"       ref={register({ required: true })} placeholder="Pasword" onKeyUp={keyboardEvents}/>
                <span className='iconPass' onClick={switchShownPass}><FontAwesomeIcon icon={ showPass ? faEyeSlash : faEye } className="showPassIcon" /></span>
                <input type={showEncrypKey ? "text":"password"} name="encrypKey" id="encrypKey"   className="password input login-input"       ref={register({ required: true })} placeholder="EncrypKey" onKeyUp={keyboardEvents}/>
                <span className='iconEncrypKey' onClick={switchShownEncrypKey}><FontAwesomeIcon icon={ showEncrypKey ? faEyeSlash : faEye } className="showEncrypKeyIcon" /></span>
                { (!loading) ? <Button disabled={loading} label="Come As You Are" id="button" classN="button-primary button login-button"/> : <Loader/> }
            </form>
            <div className='containerForgotPass'>
                <button className="linkForgotPass" onClick={toggleClass} > Forgot Password? </button>
            </div>   
        </>        
    );
}

export default Signup;