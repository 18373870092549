import React from "react";
import "./Footer.css"

const Footer = () => {    
    return (
        <div id="footer-container">
            <div className="footer">
                <p>Copyright © 2023 by NexLev Solutions, LLC. All rights reserved. Life With Abba ® is a trademark of NexLev Solutions, LLC.</p>
            </div>
        </div>
    );
};
export default Footer;