import React          from "react";
import PostTickToDate from "./PostTickToDate";

const PostDate = (props) => {
    return (
        <div className="post-date">
            <span className="date">
                <PostTickToDate tickDate={props.timeLabel} formatDate={props.display} />
            </span>
        </div>
    );
};

export default PostDate;
