import React, { useState } from "react";
import "./DropMenuUser.css";
import Switch, { Case } from "react-switch-case";
import { useHistory }  from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import logoUser from "../../../images/user2.png";

const DropMenuUser = (props) => {
	const [items]= useState(props.items || []);
	const [showItems, setShowItems]= useState(false);

    const dropDown = () => {
		setShowItems(!showItems);		
    };

    const history = useHistory();
    const logout = () =>{
        history.push("/login");
    }
    return (
        <div className="menuUser">
            {items.map((item) => (
                <Switch key={item.id} condition={String(item.type)}>
                    <Case value="search">
                        <FontAwesomeIcon icon={faSearch} onClick={dropDown}/>
                    </Case>
                    <Case value="menu">
                        <div className="logoUserContainer" onClick={dropDown} > 
                            <img src={logoUser} className="logoUser" alt="Logo-User" /> 
                        </div>
                    </Case>
                </Switch>
            ))}            
            <div className="arrowMenu" style={{ display: showItems ? "block" : "none"}}></div>
            <div style={{ display: showItems ? "block" : "none"}} className="hidenMenu" >
                {items.map((item) => (
                    <Switch key={item.id} condition={String(item.type)}>
                        <Case value="search">
                            <div className=""  style={{padding:"10px"}}>
                                <input type="text" className="textBox-search"/>
                            </div>
                        </Case>
                        <Case value="menu">
                            <div  style={{padding:"10px"}}>
                                <span className="menuItem-logOut" key={item.id} onClick={logout}>
                                    <p>{item.value}</p>
                                </span> 
                            </div>
                        </Case>
                    </Switch>
                ))}
            </div>
        </div>
    );
}

export default DropMenuUser;
