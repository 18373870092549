import React from "react";
const moment = require("moment-timezone");



const PostTickToDate = (props) => {
    const ticksSinceEpoch        = props.tickDate - 621355968000000000;
    const millisecondsSinceEpoch = ticksSinceEpoch / 10000;        
    const postDate               = new Date(millisecondsSinceEpoch);
    const formatedDatetimeSinglePost       = moment.tz(postDate, "").format("MMM DD, YYYY | h:mm A");
    const formatedDatetime       = moment.tz(postDate, "").format("MMM DD | h:mm A");
 
    return (
        <p className="singlePostDate">
            {props.formatDate === "all" ? (formatedDatetime) : (formatedDatetimeSinglePost)}
        </p>
    );
    
};
export default PostTickToDate;