import React from "react";
import myLifeWithAbbaImg from "../images/logo-premium.png";
import Carousel from "infinite-react-carousel";
import Premium1 from "../components/Premium/Premium1";
import Premium2 from "../components/Premium/Premium2";
import Premium3 from "../components/Premium/Premium3";
import Premium4 from "../components/Premium/Premium4";
import Premium5 from "../components/Premium/Premium5";
import Premium6 from "../components/Premium/Premium6";
import Button from "../components/Button/Button";

const PremiumPricing = () => {
    const settings = {
        arrows: false,
        dots: true,
        duration: 100,
        pauseOnHover: false,
        swipe: false
    };
    return (
        <div className="container">
            <img
                src={myLifeWithAbbaImg}
                className="login-logo logo"
                alt="Logo-Abba"
            />
            <div>
                <Carousel {...settings}>
                    <div>
                        <Premium1 />
                    </div>
                    <div>
                        <Premium2 />
                    </div>
                    <div>
                        <Premium3 />
                    </div>
                    <div>
                        <Premium4 />
                    </div>
                    <div>
                        <Premium5 />
                    </div>
                    <div>
                        <Premium6 />
                    </div>
                </Carousel>
            </div>
            <div className="plan-section">
                <span className="plan">Choose A Plan</span>
                <Button
                    price="$3.49/month" month="Get Life With Abba Premium Monthly" 
                    classN="button-primary button button-price"
                />
                <Button
                    price="$34.99/year" month="Get Life With Abba Premium Annual"
                    classN="button-primary button button-price"
                />
            </div>
            <div className="plan-information">
                <span className="information-tittle">
                    <strong>Cancel anytime and keep your data</strong>
                </span>
                <p>
                    Subscription automatically renews at $3.49, unless
                    auto-renew is turned off at least 24-hours before the end of
                    the current period. Account will be charged for renewal
                    within 24 hours prior to the end of the current period.
                    Subscriptions may be managed and auto-renewed turned off in
                    Account Settings. The subscription fee will be charged to
                    iTunes account at confirmation of purchases. Any unused
                    portion of a free trial peroid will be forteited once
                    upgraded to the subscription.
                </p>
            </div>
        </div>
    );
};

export default PremiumPricing;
