import React             from "react";
import Button            from "../components/Button/Button";
import { useHistory }      from "react-router-dom";
import myLifeWithAbbaImg from "../images/logo.png";

const About = () => {
    const history = useHistory();
    return (
        <div id="about">
            <img src={myLifeWithAbbaImg} className="App-logo logo" alt="Logo-Abba" />
            <div className="about-text">
                <span className="about-tittle">
                    Share <span className="lifewithabba-text">Life With Abba</span>, your
                    <p>Heavenly Father!</p>
                </span>
                <p>
                    Social media provides a simple platform for sharing your
                    experiences. Using With Abba, it’s easy to bring your
                    Heavenly Father into the conversation.
                </p>
                <p>
                    By sharing, socializing and engaging with daily verses,
                    prayers and experiences, you’ll move boldly towards the
                    throne.
                </p>
                <p>
                    The Lord is light—so why keep Him from any aspect of your
                    day-to-day life? Be in communion With Abba with every share.
                </p>
                <p className="last-text">
                    God hath sent forth the Spirit of his Son into your hearts,
                    crying, <span>Abba</span>, Father. - Gal 4:6
                </p>
            </div>
            <Button label="GO BACK" click={()=> history.push("/")} classN="button-primary button" />
        </div>
    );
};

export default About;
