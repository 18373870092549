import React from "react";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import momentDurationFormatSetup from "moment-duration-format";
export default function Bar(props) {

    const { duration, curTime, onTimeUpdate } = props;
    const curPercentage = (curTime / duration) * 100;

    function formatDuration(duration) {
        return moment
          .duration(duration, "seconds")
          .format("mm:ss", { trim: false });
      }

    function calcClickedTime(e) {
        const clickPositionInPage = e.pageX;
        const bar = document.querySelector(".bar__progress");
        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;
        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = duration / barWidth;
        return timePerPixel * clickPositionInBar;
    }

    function handleTimeDrag(e) {
        onTimeUpdate(calcClickedTime(e));

        const updateTimeOnMove = (eMove) => {
            onTimeUpdate(calcClickedTime(eMove));
        };

        document.addEventListener("mousemove", updateTimeOnMove);

        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", updateTimeOnMove);
        });
    }

    return (
        <div className="bar">
            <span className="bar__time timeStart">{formatDuration(curTime)} </span>
            <span className="bar__time timeEnd">{formatDuration(duration)}</span>
            <div className="bar__progress audioBarProgress"
                 style={{ background: `linear-gradient(to right, #008AA5 ${curPercentage}%, #ccc 0)`,
                          height: '5px',
                          position: 'absolute',
                          width: '100%',
                          bottom: '-27px',
                          display: 'flex',
                          alignItems: 'center'}}
                 onMouseDown={e => handleTimeDrag(e)}
                >
                <span className="bar__progress__knob"
                      style={{ left: `${curPercentage - 2}%`,
                               height: '20px',
                               width: '20px',
                               borderRadius: '50%',
                               background: '#008AA5',
                               position:'relative'}} />
            </div>
        </div>
    );
}
