import React, {memo} from "react";
import GetPosts from "../components/Posts/Posts";
import NavMenu from "../components/NavMenu/NavMenu";
import Footer from "../components/Footer/Footer";

const Home = () => {
    return (
        <>
            <NavMenu />
            <div id="root">
                <div className="container posts-container">
                    <GetPosts display={'all'}/>
                </div>    
            </div>  
            <Footer />
        </>
    );
};

export default memo(Home);
