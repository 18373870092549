import React, { useState } from "react";
import logoImage from "../../images/logo.png";
import * as Realm from "realm-web";
import env from "react-dotenv";
import "./Style.css";
import Swal from "sweetalert2";
import { useHistory }  from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ResetPassword = () => {
    const REALM_APP_ID = env.REALM_APP_ID;
    const app          = new Realm.App({ id: REALM_APP_ID });
    const [sameNewPass, setSameNewPass]= useState(true);
    const [lowerCase, setLowerCase]= useState(true);
    const [capitalLetter, setCapitalLetter]= useState(true);
    const [hasNumber, setHasNumber]= useState(true);
    const [newPassOK, setNewPassOK]= useState(false);
    const history               = useHistory();
    const [showPass, setShowPass]= useState(false);
    const [showPassAgain, setShowPassAgain]= useState(false);


    async function resetPass() {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let token = urlParams.get('token');
        let tokenID = urlParams.get('tokenId');
        let newPass = document.getElementById('newPass').value;
        let newPassAgain= document.getElementById('newPassAgain').value;

        if (newPass=== newPassAgain && newPassOK) {
            try {
                await app.emailPasswordAuth.resetPassword({ password: newPass, token, tokenID });
                Swal.fire({
                    title: 'Your password was reset.',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        history.push("/login");
                    }
                  });
            } catch (error) {
                console.log(error);
            }
        }
    }

    const keyboardEvents = (event) =>{
        let newPass = document.getElementById('newPass').value;
        event.persist();        

        if (/[a-z]/.test(newPass)) {
            setLowerCase(true);            
        }else{
            setLowerCase(false);
        }
        if (/[A-Z]/.test(newPass)) {
            setCapitalLetter(true);            
        }else{
            setCapitalLetter(false);
        }
        if (/[0-9]/.test(newPass)) {
            setHasNumber(true);            
        }else{
            setHasNumber(false);
        }

        if (lowerCase && capitalLetter && hasNumber) {
            setNewPassOK(true);            
        }
    }

    const samePass = (event) =>{
        let newPass = document.getElementById('newPass').value;
        let newPassAgain= document.getElementById('newPassAgain').value;
        event.persist();    
        
        if (newPassAgain !== newPass) {
            setSameNewPass(false);
        }else{
            setSameNewPass(true)
        }
    }

    const switchShownPass = () => {
        setShowPass(!showPass);
    };
    const switchShownPassAgain = () => {
        setShowPassAgain(!showPassAgain);
    };

    return (
        <div id="root" className="rootLogin resetPassRoot">
            <div className="container login-container resetContainer">
                <div id="login-form">
                    <div className="login-data resetPassForm">
                        <img src={logoImage} className="logoLoginImg" alt="Logo-Abba" />
                        <div className="resetData">
                            <p className="resetTextTitle"> Create A Strong Password </p>                        
                            <p className="resetTextDescription">
                                Your password must be at least 8 characters, one
                                letter, one capital letter, one number and not
                                be the same as the account email.
                            </p>
                            {lowerCase ? (<span></span>) : (<p style={{color:"red", fontSize:"14px", textAlign:"left", width:"274px", margin:"0 auto"}}>Need one letter</p>)}
                            {capitalLetter ? (<span></span>) : (<p style={{color:"red", fontSize:"14px", textAlign:"left", width:"274px", margin:"0 auto"}}>Need one capital letter</p>)}
                            {hasNumber ? (<span></span>) : (<p style={{color:"red", fontSize:"14px", textAlign:"left", width:"274px", margin:"0 auto"}}>Need number</p>)}
                            <div className="newPassContainer">
                                <input type={showPass ? "text":"password"} name="newPass" id="newPass" className="newPass input login-input" onKeyUp={keyboardEvents} placeholder="New Password" />
                                <span className='iconPass' onClick={switchShownPass}><FontAwesomeIcon icon={ showPass ? faEyeSlash : faEye } className="showPassIcon" /></span>
                            </div>                            
                            {sameNewPass ? (<span></span>) : (<p style={{color:"red", fontSize:"14px", textAlign:"left", width:"274px", margin:"0 auto"}}>Password's are diferents</p>)}
                            <div className="newPassAgainContainer">
                                <input type={showPassAgain ? "text":"password"} name="newPassAgain" id="newPassAgain" className="newPassAgain input login-input" onKeyUp={samePass} placeholder="New Password, Again" />
                                <span className='iconPass' onClick={switchShownPassAgain}><FontAwesomeIcon icon={ showPassAgain ? faEyeSlash : faEye } className="showPassIcon" /></span>
                            </div>                            
                            <button className="button-primary button login-button" onClick={resetPass}> Reset Password </button>
                        </div>
                        <div className="backToLoginContainer">
                            <a href="/login"> Back To Login</a>
                        </div>
                        <div className="container-title">
                            <p className="tittle"> Welcome, <span className="text-beloved">Beloved.</span> </p>
                            <span>Came in and share your ...</span>
                        </div>
                    </div>
                </div>
            </div>                
        </div>
    );
};

export default ResetPassword;