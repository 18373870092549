import React from "react";
import NavMenu from "../components/NavMenu/NavMenu"

const Error = () => {
    return (
        <>
            <NavMenu />
            <div id="root">
                <div className="container error">
                    <h3>Sorry, this page isn't available.</h3>
                    <span>The link you followed may be broken, or the page may have been removed. Go back to Home.</span>
                </div>
                
            </div>
        </>
    );
};

export default Error;
