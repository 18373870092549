import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const PostInformation = (props) => {
    const [likes, setLikes] = useState(props.dynamicContent.Likes);
    const [newlikes, setNewlikes] = useState(props.dynamicContent.Likes);

    useEffect(() => {
        if (likes !== newlikes) {
            const updateLikes = async () => {
                try {
                    const url= "https://work.nexlevsolutions.com/post/likes/:"+props._id;

                    const username= Buffer.from(localStorage.getItem("text1"), "base64").toString();
                    const pass= Buffer.from(localStorage.getItem("text2"), "base64").toString();
                    const encrypKey= Buffer.from(localStorage.getItem("text3"), "base64").toString();

                    let myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    let raw = JSON.stringify({"user":username,"password":pass,"encrypKey":encrypKey,"id":props._id,"likes":likes});

                    let requestOptions = {
                        method: 'PATCH',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };
                    const requestFetch= await fetch(url, requestOptions)
                    const resultFetch= await requestFetch.json();

                    setNewlikes(likes);
                    return resultFetch;
                } catch (error) {
                    setLikes(likes - 1);
                    setNewlikes(likes - 1);
                }
            };
            updateLikes();
        }
    }, [likes, newlikes, props]);

    const addlike = () => {
        setLikes(likes + 1);
    };    

    return (
        <div className="post-information">
            <span className="comment">
                <FontAwesomeIcon
                    icon={faComment}
                    className="icon-comment"
                />
                <span id={`comment-${props._id}`}>
                    {props.dynamicContent.Comments !== null
                        ? props.dynamicContent.Comments.length
                        : 0}
                </span>                
            </span>
            <button className="like" onClick={addlike}>                
                <FontAwesomeIcon icon={faHeart} className="icon-like" />
                {likes}
            </button>
        </div>
    );
};

export default PostInformation;
