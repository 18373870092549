import React, { useState,useEffect } from 'react';
import { useForm }         from 'react-hook-form';
import Loader              from "../Loader/Loader";
import PostTickToDate      from "./PostTickToDate";

const PostComments = (props) => {
    const [comments, setComments]    = useState([]);
    const [loading, setLoading]      = useState(false);
    const { register, handleSubmit } = useForm(); 
    const timeTicks                  = () => {
        const dateNow = new Date();
        var offset = -300;
        var estDate = new Date(dateNow.getTime() + offset*60*1000);
        const ticks   = ((estDate*10000)+621355968000000000);
        
        return ticks;
    } 

    const username= Buffer.from(localStorage.getItem("text1"), "base64").toString();
    const pass= Buffer.from(localStorage.getItem("text2"), "base64").toString();
    const encrypKey= Buffer.from(localStorage.getItem("text3"), "base64").toString();

    useEffect(() => {
        let comments = (props.dynamicContent.Comments !== undefined && props.dynamicContent.Comments !== null) ? props.dynamicContent.Comments : [];
        comments.sort(function (a, b) {
            return b.timeforComment - a.timeforComment;
        });
        setComments(comments);
    }, [props.dynamicContent.Comments])

    useEffect(() => {
        document.getElementById(`comment-${props._id}`).textContent = (comments !== undefined && comments !== null) ? comments.length : 0;
    }, [comments,props])
    
    async function onSubmit(data) {        
        if (data) {
            if(data.comment !== ''){
                setLoading(true);
                const updateComments = async () =>{
                    try {
                        const url= "https://work.nexlevsolutions.com/post/comments/:"+props.display;

                            let myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");

                            let raw = JSON.stringify({"user":username,"password":pass,"encrypKey":encrypKey,"id":props.display,"comment":data.comment,"timeComment":timeTicks()});

                            let requestOptions = {
                                method: 'PATCH',
                                headers: myHeaders,
                                body: raw,
                                redirect: 'follow'
                            };
                                    
                            const requestFetch= await fetch(url, requestOptions)
                            const resultFetch= await requestFetch.json();

                        if(resultFetch.message.modifiedCount === 1){
                            const url= "https://work.nexlevsolutions.com/post/:"+props._id;

                            let myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");

                            let raw = JSON.stringify({"user":username,"password":pass,"encrypKey":encrypKey,"id":props._id});

                            let requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: raw,
                                redirect: 'follow'
                            };
                                    
                            const requestFetch= await fetch(url, requestOptions)
                            const resultFetch= await requestFetch.json();

                            let comments = resultFetch.message[0].dynamicContent.Comments;

                            comments.sort(function (a, b) {
                                return b.timeforComment - a.timeforComment;
                            });
                            setComments(comments);
                            setLoading(false);

                            Array.from(document.querySelectorAll(".comment-area")).forEach(
                                input => (input.value = "")
                            );
                        }
                        return true;
                    } catch (error) {
                        setLoading(false);
                    }
                }
                updateComments();
            }
        } else {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="post-comments">
                { 
                    (comments !== undefined && comments !== null)? 
                    (
                        comments.map((el) => (
                            <div key={el.TimeforComment} className="singleComment">                              
                                <p className="singlePostComment">{el.Text}</p>
                                <PostTickToDate tickDate={el.TimeforComment}/>
                            </div>
                        ))
                    ) : (
                        ""
                    )
                }
            </div>
            <section className="new-comment" id="newComment">
                <div className="comment-container">
                    <form className="comment-form"  onSubmit={handleSubmit(onSubmit)}>
                        { (loading) && <Loader/> }
                        <textarea aria-label="Add a comment…" name="comment"  ref={register({ required: true })} placeholder="Add a comment…" className="input comment-area" autoComplete="off" autoCorrect="off"></textarea>
                        <button className="submit">Post</button>
                    </form>
                </div>
            </section>
        </>
    );
};

export default PostComments;