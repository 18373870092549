import React from "react";
import premiumImg from "../../images/premium3.png";
import "./Style.css"

const Premium3 = () => {
    return (
        <div className="slide">            
            <span className="unlimited-text life-better-text encrypted-text">Unlimited Photo & Video Posts</span>
            <div>
                <span className="capture-text encrypted-inf-text data-text">Capture and share special moments With</span> 
                <span className="encrypted-inf-text">Abba using Photos and Videos.</span>
            </div>     
            <div className="premiumImg">
                <img src={premiumImg} alt="Premium-3" />
            </div>                   
            <a href="http://" className="footer-text foter-text">See the full list of Premium features</a>
        </div>
    );
};

export default Premium3;