import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const widthScreen = window.screen.width;
    let textLenght    = 90;

    if(widthScreen < 480) {
        textLenght = 50;
    }
    
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
        if (text.length >= textLenght) {
            if (isReadMore) {
                document.getElementById("newComment").style.display= "none";         
            }else{
                document.getElementById("newComment").style.display= "block"; 
            }
        }
    }; 

    return (
        text.length === 0 ? 
            (<span> </span>) 
            : 
            (<>
                <span className="text" id="txtPost">
                    {isReadMore ? text.slice(0, textLenght) : text}
                </span>
                <div onClick={toggleReadMore} className="readOrHide">
                    {text.length< textLenght ?
                    (<span> </span>) 
                    :
                    (
                        isReadMore ? 
                            <div className="showMoreContainer"><FontAwesomeIcon icon={faSortDown} className="showMore" /></div>  
                            : 
                            <div className="showMoreContainer"><FontAwesomeIcon icon={faSortUp} className="showLess" /></div>  
                        
                    )
                    }
                </div>
            </>
            )
    );
};

export default ReadMore;