import React from "react";
import Switch, { Case } from "react-switch-case";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faSmile, faBible, faPencilAlt, faMicrophone, faPlay } from "@fortawesome/free-solid-svg-icons";
import PostDate            from "./PostDate";
import PostInformation     from "./PostInformation";
import ReadMore from "./ReadMore";

const PostDescription = (props) => {
    return (
        <>
        <div className="postDescription">                  
            <Switch condition={String(props.type)}>
                <Case value="1">                    
                    <span><FontAwesomeIcon icon={faCamera}     size="2x" className="menu-icon" /></span>
                </Case>
                <Case value="2">
                    <span><FontAwesomeIcon icon={faSmile} size="2x" className="menu-icon" /></span>                    
                </Case>
                <Case value="3">
                    <span><FontAwesomeIcon icon={faBible}     size="2x" className="menu-icon" /></span>
                </Case>
                <Case value="4">
                    <span><FontAwesomeIcon icon={faPencilAlt}      size="2x" className="menu-icon" /></span>
                </Case>
                <Case value="5">
                    <span><FontAwesomeIcon icon={faMicrophone}      size="2x" className="menu-icon" /></span>
                </Case>
                <Case value="6">
                    <span><FontAwesomeIcon icon={faPlay}  size="2x" className="menu-icon" /></span>
                </Case>
            </Switch>
            <div className="postDateInformation">
                <PostDate {...props}/>
                <PostInformation {...props}/>
            </div>            
        </div>
        <div className="postUserText">
            <ReadMore>
                {props.dynamicContent.UserTextLabel}
            </ReadMore>
        </div>  
    </>     
    );
    
};
export default PostDescription;
