import React from "react";
import premiumImg from "../../images/premium5.png";
import "./Style.css"

const Premium3 = () => {
    return (
        <div className="slide">
            <span className="synt-text life-better-text encrypted-text">Sync Across All Your Devices</span>
            <div>
                <span className="data-text encrypted-inf-text">Data is updated in realtime across your iOS</span> 
                <span className="encrypted-inf-text">devices and other future platforms.</span>
            </div>
            <div className="premiumImg">
                <img src={premiumImg} alt="Premium-5" />
            </div>                 
            <a href="http://" className="footer-text foter-text">See the full list of Premium features</a>
        </div>
    );
};

export default Premium3;