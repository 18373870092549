import React from "react";
import premiumImg from "../../images/premium4.png";
import "./Style.css"

const Premium3 = () => {
    return (
        <div className="slide">
            <div>
            <span className="unlimitedAudio-text life-better-text encrypted-text">Unlimited Audio Posts</span>
            <span className="recording-text encrypted-inf-text">Share Life With Abba with recording audio.</span>
            </div>            
            <div className="premiumImg premium-4">
                <img src={premiumImg} alt="Premium-4 " />
            </div>            
            <a href="http://" className="footer-text">See the full list of Premium features</a>
        </div>
    );
};

export default Premium3;