import React from "react";
import premiumImg from "../../images/premium1.png";
import "./Style.css"

const Premium1 = () => {
	return (
		<div className="slide">
            <span className="life-better-text"><span className="life-text">Life With Abba</span> is better</span> 
            with Premium
            <div className="premiumImg">
                <img src={premiumImg} alt="Premium-1"/>
            </div>            
            <a href="http://" className="footer-text">See the full list of Premium features</a>
        </div>
	);
 }

 export default Premium1;