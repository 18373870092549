import React from "react";
import Play from "../Play";
import Pause from "../Pause";
import VideoBar from "./VideoBar";
import useVideoPlayer from "./useVideoPlayer";
import "../style.css";

function Video(props) {
    const { 
        curTime, 
        duration, 
        setClickedTime, 
        playing, 
        setPlaying} = useVideoPlayer();

    const postVideo = () => {
        try {
            return `data:video/mp4;base64,${props.staticContent.FileName}`;
        } catch (error) {
            return '';
        }
    };

    const mouseIn= () =>{
        document.getElementById("videoControlsEvt").style.display= "block";
    }

    const mouseOut= () =>{
        document.getElementById("videoControlsEvt").style.display= "none";
    }
        
    return (
        <>
            {playing ? (
                <div className="player" onClick={() => setPlaying(false)} onMouseEnter={mouseIn} onMouseLeave={mouseOut} >
                    <video id="video">
                        <source src={postVideo()} type="video/mov" preload="metadata" />
                        Your browser does not support the <code>video</code> element.
                    </video>
                    <div id="videoControlsEvt" className= "videoControls videoControlPause" >
                        <VideoBar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} />
                    </div>
                </div>
                ) : (
                <div className="player" onClick={() => setPlaying(true)}  >
                    <video id="video">
                        <source src={postVideo()} type="video/mov" preload="metadata" />
                        Your browser does not support the <code>video</code> element.
                    </video>
                    <div className="videoControls">
                    {playing ? (
                            <Pause handleClick={() => setPlaying(false)} />
                        ) : (
                            <Play handleClick={() => setPlaying(true)} />
                        )}
                    </div>
                </div>
                )}            
        </>
    );
}

export default Video;