import React, {memo} from "react";
import PostType                        from "./PostType";
import PostDescription                 from "./PostDescription";
import PostComments                    from "./PostComments";

const SinglePost = (props) => {
    const handleModalPost = (e) => e.stopPropagation();
    console.log("SinglePost", props.changePostModal);

    return (
        <article id="singlePost" className={"singlePost "+ props.changePostModal} onClick={handleModalPost}>
            <PostType {...props} />
            <div className="infoPost">
                <PostDescription {...props} />
                <PostComments {...props} />
            </div>
        </article>
    );
};

export default memo(SinglePost);