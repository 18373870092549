import React from "react";
import "./NavMenu.css";
import { useHistory }  from 'react-router-dom';

const NavAccess = (post) => {
    const history = useHistory();
    const login = () =>{
        history.push("/login");
    }

    return (
        <div className="navAccess">
            <span onClick={login} className="logIn">Log In</span>
            <a href="https://mylifewithabba.com/" target="_blank" rel="noreferrer" className="signUp">Sign Up</a>
        </div>
    );
};
export default NavAccess;
