import React from "react";
import { useState, useContext,useEffect } from "react";
import "./NavMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faComment } from "@fortawesome/free-solid-svg-icons";
import DropMenuUser from "./DropMenuUser/DropMenuUser";
import  SortContext from "../../contexts/SortContext"
import DropCalendarSearch from "./DropCalendarSearch/DropCalendarSearch"

const NavOptions = (post) => {
    const [likesSorting, setLikesSorting] = useState(false);
    const [commentSorting, setCommentSorting] = useState(false);
    const {setPostSorting} = useContext(SortContext);
    
    const toggleClass = () => {
        setLikesSorting(!likesSorting);
    };
    const toggleClassComment = () => {
        setCommentSorting(!commentSorting);
    };

    useEffect(() => {
        if(likesSorting){
            setPostSorting('likes')
        }else{
            setPostSorting('date')
        }
    },[likesSorting,setPostSorting])

    return (
        <div className="navOptions">
            <DropCalendarSearch />
            <button id="commentButton" className={commentSorting ? 'sorted': null} onClick={toggleClassComment} disabled >
                <FontAwesomeIcon icon={faComment} className="iconComment" />
            </button>
            <button id="likesButton" className={likesSorting ? 'sorted': null}  onClick={toggleClass} >
                <FontAwesomeIcon icon={faHeart} className="iconLikes" />
            </button>
            <DropMenuUser items={[{ id: 2, value: "Log Out", type: "menu" }]} />
        </div>
    );
};
export default NavOptions;
