import LoadingImage from "../../images/LoadingImage.png";
import "./style.css";

const LoadingPosts = (post) => {
    return (
        <div className="loading-posts post">
            <div>
                <img src={LoadingImage} alt="" />
            </div>
            <div className="blink">
                <span className="dataInformation"> </span>
                <span className="dataDescription"> </span>
                <span className="dataComment"> </span>
                <span className="newComment"> </span>
            </div>
        </div>
    );
};
export default LoadingPosts;
