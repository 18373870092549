import React, { useContext, useState, useEffect } from "react"
import * as Realm from "realm-web";
import env from "react-dotenv";

const REALM_APP_ID = env.REALM_APP_ID;
const app          = new Realm.App({ id: REALM_APP_ID });
const AuthContext  = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {    
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true)
    const [mongoDb,setMongoDb] = useState()

    async function login(email,password, encrypKey) {
        try {
            const credentials   = Realm.Credentials.emailPassword(email,password);
            const loginResponse = await app.logIn(credentials);
            const mongoResponse = app.currentUser.mongoClient("mongodb-atlas");

            localStorage.setItem('text1', Buffer.from(email).toString("base64"));
            localStorage.setItem('text2', Buffer.from(password).toString("base64"));
            localStorage.setItem('text3', Buffer.from(encrypKey).toString("base64"));
            localStorage.setItem('text4', 'true');
            setMongoDb(mongoResponse);
            setUser(loginResponse);
            setLoading(false);
            return true;
        } catch (error) {
            console.log('Fetch Error :-S', error);
            setUser(false);
            return false;
        }
    }
    
    function logout() {
        return false
    }

    useEffect(() => {
        try {
            const userId = app.currentUser.id
            if(userId){
                const mongoResponse = app.currentUser.mongoClient("mongodb-atlas");
                setMongoDb(mongoResponse);
                setUser(true)
            }else{
                setUser(false)
            }
            return setLoading(false)
        } catch (error) {
            setUser(false)
            return setLoading(false)
        }
    }, [])

    const value = {
        user,
        login,
        logout,
        mongoDb
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}